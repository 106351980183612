import 'core-js/stable';
import 'regenerator-runtime/runtime';
import $ from 'jquery';
import 'slick-carousel';
import { Bases } from './bases';
// import { intro } from './intro';
import { aboutEvent } from './about';

let DEV = window.DEV || {};
window.DEV = (($) => {
	'use strict';

	const common = {

		imageChange(selector){
			$(selector).each((idx, el) => {
				const desktopSrc = $(el).data('image-desktop');
				const mobileSrc = $(el).data('image-mobile');
				const tag = $(el).prop('tagName');
				if( tag === 'IMG' ){
					if (window.innerWidth <= Bases.breakPointMobile) {
						$(el).attr('src', mobileSrc).addClass('shown');
					} else {
						$(el).attr('src', desktopSrc);
					}
				}else{
					if (window.innerWidth <= Bases.breakPointMobile) {
						$(el).css('background-image', 'url(' + mobileSrc + ')').addClass('shown');
					} else {
						$(el).css('background-image', 'url(' + desktopSrc + ')');
					}
				}
			});
		},

		tabSelected(){
			const target = $('.works__tab');
			const selectedBtn = target.find('.tab_selected');
			const button = target.find('.works__tab_menu a');

			selectedBtn.on('click', function(){
				if( target.hasClass('active')){
					target.removeClass('active');
				}else{
					target.addClass('active');
				}	
			});
			
			button.on('click', function(){
				const text = $(this).text();
				const el = $(this).parent();
				if( !el.hasClass('is-active')){
					selectedBtn.text(text);
					el.addClass('is-active').siblings().removeClass('is-active');
					target.removeClass('active');
				}	
			});
		},

		viewingTalkBtn(e){
			const _this = $(e.currentTarget);
			if( _this.hasClass('clicked')){
				_this.removeClass('clicked');
			} else{
				_this.addClass('clicked');
			}
		},

		pageTo(){
			const hash = $(location).attr('hash').substring(1);
			console.log(hash);
			if( hash !== '' ){
				const target = $('.about__' + hash);
				const top = target.offset().top - $('header').outerHeight() + 1;
				$('html').animate({ scrollTop : top }, 0);
			}
		},

		init(){
			common.imageChange('.js-images');
			$(window).resize(function () {
				common.imageChange('.js-images');
			});
			// 다른곳 클릭 시 이벤트 해제
			$(document).on('click', function(e){
				const target = $(e.target);
				if( !target.hasClass('btn_talk') && !target.parents().hasClass('btn_talk')) {
					$('.btn_talk').removeClass('clicked');
				}
				if( !target.hasClass('toggle_elem') && !target.parents().hasClass('toggle_elem')) {
					$('.toggle_elem').removeClass('is-active');
				}
			});

			// works tab
			if($('.works__tab').length > 0){
				common.tabSelected();
			}
		}
	}

	const nav = {
		localeChange(){
			const locale = $('header .locale');
			const currentButton = locale.find('.current');
			const selectButton = locale.find('.options button');

			currentButton.on('click', () => {
				if( locale.hasClass('is-active')){
					locale.removeClass('is-active');
				}else{
					locale.addClass('is-active');
				}
			});
			selectButton.on('click', e => {
				let _this = $(e.currentTarget);
				let text = _this.text();
				currentButton.text(text);
				locale.removeClass('is-active');
				_this.parent().addClass('selected').siblings().removeClass('selected');
			})
		},

		// 리사이즈시 언어 선택 셀렉박스 접기
		localeChangeFold(){
			const locale = $('.nav__wrap .locale');
			if( locale.hasClass('is-active')) locale.removeClass('is-active');
		},

		navScroll(){
			const header = $('header');
			const scrollTop = $(window).scrollTop();
			const standard = header.outerHeight();
			if( scrollTop > standard ){
				header.addClass('is-scroll');
			}else{
				header.removeClass('is-scroll');
			}

			const footer = $('footer');
			const footerTop = footer.offset().top;
			const footerHeight = footer.outerHeight();
			const windowHeight = window.innerHeight;
			const btnTalk = $('.btn_talk');
			let margin = '20px';
			if( window.innerWidth <= Bases.breakPointMobile ) margin = '2.7778vw';
			// console.log(footerTop, scrollTop, windowHeight)
			if( footerTop > scrollTop + windowHeight ){
				btnTalk.attr('style', '');
			}else{
				btnTalk.css({ position: 'absolute', 'bottom': `calc( ${footerHeight}px + ${margin}` });
			}
		},

		navControls() {
			const btn = $('.menu_btn');
			const btnText = btn.data('text');
			const body = $('body');
			btn.on('click', () => {
				if( body.hasClass('nav-opens')){
					body.removeClass('nav-opens');
					btn.find('span').text(btnText[0]);
				}else{
					body.addClass('nav-opens');
					btn.find('span').text(btnText[1]);
				}
			})
		},

		init(){
			nav.localeChange();
			if($('#container').length <= 0) return;
			nav.navControls();

			//vr header 아닐 경우만 실행
			if($('.vr_header').length === 0){
				nav.navScroll();
				$(window).on('scroll', function(){
					nav.navScroll();
				});
			}
			$(window).on('resize', function(){
				const body = $('body');
				if( window.innerWidth > Bases.breakPointMobile ){
					if(body.hasClass('nav-opens')) body.removeClass('nav-opens');
				}
				nav.localeChangeFold();
			});
		}
	}

	const slider = {

		mainVisualSlider(){
			const slider = $('.main__visual .slider');
			const delay = 5000;
			let slickTimer = null;
			// init 는 slick 실행 전에 선언해야 함
			slider.on('init reInit afterChange', function () {
				$('.slider-progress .bar').addClass('on');
				// 모바일 터치 시 자동재생 해제되서 setTimeout 으로 자동재생 구현함
				if( slickTimer ){
					clearTimeout(slickTimer);
					refresh();
				}
			});
			slider.slick({
				infinite: true,
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				dots: true,
				fade: true,
				// autoplay:true,
				// autoplaySpeed: 5000,
				pauseOnHover: false,
				pauseOnFocus: false,
				customPaging: function (slider, i) {
					return  (i + 1) + '/' + slider.slideCount;
				},
				responsive: [
					{
						breakpoint: 768,
						settings: {
							pauseOnFocus: false,
							focusOnSelect: true,
						}
					},
				]
			});
			refresh();
			slider.on('beforeChange',function(){
				$('.slider-progress .bar').removeClass('on');
			});
			function refresh(){
				slickTimer = setTimeout(function(){
					slider.slick('slickNext');
				}, delay);
			}
		},

		mainWorkSlider(){
			$('.main__works .slider').slick({
				infinite: true,
				slidesToShow: 4,
				slidesToScroll: 1,
				arrows: false,
				variableWidth: true,
				swipeToSlide: true,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 3,
							//slidesToScroll: 3,
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
							//slidesToScroll: 2
						}
					},
				]
			});

		},

		workLeftControl(){
			//메인 work 슬라이더 왼쪽 여백 맞추기
			const screenWidth = $(window).width();
			const containerWidth = $('.main__inner').width();
			const leftWidth = (screenWidth - containerWidth) / 2;
			//item 여백
			const itemInterval = ($('.main__works .item').innerWidth() -  $('.main__works .project').innerWidth()) / 2;
			$('.main__works .contents').css({'padding-left':leftWidth - itemInterval});
		},

		workDetailSlider(){
			$('.works__detail .slider_wrap').slick({
				dots: true,
			});
		},

		aboutClientSlider(){
			$('.about__clients .slider').slick({
				infinite: true,
				slidesToShow: 2,
				slidesToScroll: 2,
				arrows: false,
				responsive: [
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							dots: true,
						}
					},
				]
			});
		},

		init(){
			if($('.main__visual').length > 0){
				slider.mainVisualSlider();
			}
			if($('.main__works').length > 0){
				slider.mainWorkSlider();
				slider.workLeftControl();
				$(window).resize(function () {
					slider.workLeftControl();
				});
			}
			if($('.works__detail').length > 0 && $('.vr_layer').length === 0){
				slider.workDetailSlider();
			}
			if($('.about__clients').length > 0 && $('.vr_layer').length === 0){
				slider.aboutClientSlider();
			}
		}
	}

	const contact = {
		
		inputFocus(){
			const $formWrap = $('.form_wrap');
			$formWrap.each(function (index, elem) {
				$(elem).on('change', function(e){
					const target = e.target;
					if (!target.matches('.label_top input[type="text"]')) return;
					if ($(target).val()) {
						$(target).parent().addClass('fill');
					} else {
						(target).parent().addClass('fill');
					}
				});

				const $input = $('.label_top .input');
				$input.on('focus', function(){
					$(this).parent().addClass('fill');
				})
				$input.on('blur', function(){
					if($(this).val()) return;
					$(this).parent().removeClass('fill');
				});
			});
		},

		checkedEmpty(element){
			let $requireInput = $('.require');
			let reCheck = false;
			if (element !== undefined){
				$requireInput = element.find('.require');
			}
			$requireInput.each(function(){
				const target = $(this);
				const text = target.data('empty-text');
				if($(this).hasClass('input')){ //체크 박스일경우
					if($(this).val() === ''){
						Bases.layerConfirm(text, function(){
							target.trigger('focus');
						});
						reCheck = true;
						return false;
					}
				}else if($(this).attr('type') === 'checkbox'){
					if(!$(this).prop('checked')){
						Bases.layerConfirm(text, function(){
							target.parent().addClass('focus');
						});
						reCheck = true;
						return false;
					}else{
						target.parent().removeClass('focus');
					}
				}
			});
			return reCheck;
		},

		submitForm(button){
			let isEmpty = contact.checkedEmpty($(button).closest('form'));
			if( isEmpty ) return;

			const $email = $(button).closest('form').find('#email');
			const testForm = contact.regExp.email;
			if($email.length > 0 && !testForm.test($email.val())){
				return Bases.layerConfirm('이메일의 형식에 맞춰서 입력해주세요', function(){
					$email.trigger('focus');
				});
			}
			// common.layerConfirm('문의 내용이 발송 되었습니다. <br> 빠른 시일 내에 담당자가 연락 드리겠습니다.');
			return true;
		},

		vrContactConfirm(text){
			const $formFrame = $('.contact__wrap .form');
			const langConfirmText = ($('html').attr('lang') === 'ko') ? '확인' : 'OK';
			const $form = $formFrame.find('form');
			$form.addClass('invisible');
			$form.append(`
			<div class="layer_confirm">
				<div class="top_icon"></div>
				<div class="message">${text}</div>
				<div class="button_wrap">
					<button type="button" class="button primary js-confirm" onclick="DEV.resetForm()">${langConfirmText}</button>
			</div>
			`);
		},

		resetForm(){
			const $form = $('.contact__wrap form');

			$('.layer_confirm').remove();
			$form.removeClass('invisible');

			$form.find('.input').val('').trigger('blur');
			$form.find('input[type="checkbox"]').prop('checked', false);


		},

		regExp : {
			email : /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
		},

		init: function(){
			if($('.contact__wrap').length > 0){
				contact.inputFocus();
			}
		}
	}

	const motion = {
		titleMotion(){
			$('.main_section-title').each(function(){
				const top = $(this).offset().top;
				const scrollTop = $(window).scrollTop();
				const windowHeight = $(window).height();
				if (scrollTop > top - (windowHeight - (windowHeight * 0.15))) {
					$(this).addClass('on');
					const $letter = $(this).find('span');
					$letter.each(function(idx){
						$(this).css('transition-delay', idx * 0.1 +'s');
					});
				}
			});
		},

		titleSlice(){
			const $title = $('.main_section-title');
			$title.each(function(){
				const title = $(this).text().trim();
				$(this).text('');
				const textArray = title.split('');
				textArray.forEach((el)=>{
					const elSpan = `<span>${el}</span>`;
					$(this).append(elSpan);
				})
			});
		},

		motion: function(){
			$('.is-motion').each(function () {
				let top = $(this).offset().top;
				let scrollTop = $(window).scrollTop();
				let windowHeight = $(window).height();
				let gap = 0.15;
				if($('.about__intro').length > 0) gap = 0.25;
				if( $(this).closest('.vr_layer').length > 0 ){
					const content = $(this).closest('.vr_layer').find('.contents');
					scrollTop = content.scrollTop();
					windowHeight = content.outerHeight();
					top = top - content.prev().outerHeight();
				}
				// console.log(scrollTop)
				if (scrollTop > Math.floor(top - (windowHeight - (windowHeight * gap)))) {
					$(this).addClass('on');
				}
				// else if (scrollTop < top) {
				// 	$(this).removeClass('on');
				// }
			});
		},

		init: function(){
			if( $('html').hasClass('vr_content')){
				$('.vr_layer .contents').on('scroll', function () {
					motion.motion();
				});
			}else{
				setTimeout(function () {
					motion.motion();
				}, 100);
				$(window).on('scroll', function () {
					motion.motion();
				});
			}

			if($('.main_section-title').length > 0){
				motion.titleSlice();
				motion.titleMotion();
				setTimeout(function () {
					motion.titleMotion();
				}, 100);
				$(window).on('scroll', function () {
					motion.titleMotion();
				});
			}
		}
	}

	const introEvent = {
		init(){
			// 로티 플레이어 실행 (인트로)
			intro.play();
			intro.onComplete = function() {
				console.log('complete');
				$('#intro .button').addClass('on');
			}
		}
	}

	const vr = {
		//vr slider 있는 레이어 오픈시
		vrLayerOpen(target){
			const el = $(target);
			Bases.layerOpen(target);

			//슬라이더 있을 시만 실행
			if(el.find('.slider_wrap').length > 0){
				el.find('.slider_wrap').slick({
					dots: true,
				});
			}
		},
		//vr slider 있는 레이어 닫을시
		vrLayerClose(element){
			const el = $(element).closest('.vr_layer');
			Bases.layerClose(el);

			//스크롤이 팝업 상단으로 이동
			const vrLayer = el;
			if(vrLayer.length > 0 && vrLayer.find('.contents').length > 0){
				vrLayer.find('.contents').scrollTop(0, 0);
			}
			//slick 중단
			if(vrLayer.find('.slider').length > 0){
				if(vrLayer.find('.slider_wrap').length > 0){
					vrLayer.find('.slider_wrap').slick('unslick');
				}else{
					vrLayer.find('.slider').slick('unslick');
				}
			}
		},
		menuToggleHandler(button){
			const $nav = $(button).closest('.nav');
			$nav.toggleClass('is-active');
		},
		vrHeaderClickHandler(){
			$('.vr_header').on('click', function(e){
				const el = $(e.target);
				const activeElem = $('.nav__wrap .is-active');
				if(el.attr('type') === 'button' && activeElem.length > 0){
					activeElem.each(function(){
						if($(this).attr('class') !== el.parent().attr('class')){
							// console.log($(this));
							// console.log(el.parent());
							$(this).removeClass('is-active');
						}
					})

				}
				
				// activeElem.removeClass('is-active');


			});
		},
		hoverBubbleBan : function(){
			$('.enterance__wrap').on('mouseover mouseenter mouseleave mouseup mousedown', function(e) {
				console.log(e.target);
				return false;
		 });
			// $('.shadow').on('mouseenter',function(e){
			// 	e.preventDefault();
			// 	e.stopPropagation();
			// });
			// $(document).on('mouseover', function(e){
			// 	// console.log(e.target);
			// 	e.target.stopPropagation();
			// 	console.log(e.target);
			// 	// console.log(`이벤트 단계 : ${e.eventPhase}`); 
      //   //   console.log(`이벤트 타깃 : ${e.target}`); 
      //   // console.log(`커런트 타깃 : ${e.currentTarget}`); 
			// })
		},



		init(){
			if($('.vr_header').length > 0){
				vr.vrHeaderClickHandler();
			}
			if($('.enter__vr .vr_bg').length > 0){
				vr.hoverBubbleBan();
				// console.log('dd');
			}
		},
	}
	
	$(document).ready(function() {
		common.init();
		nav.init();
		slider.init();
		motion.init();
		
		// 서브페이지
		// introEvent.init();
		aboutEvent.init();
		contact.init();

		//vr
		vr.init();

		// ie
		if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1) {
			$('body').addClass('ie');
		}
		// mac os
		if (navigator.userAgent.indexOf('Mac OS') <= 0){
			$('body').addClass('os_win');
		}

	});


	return {
		viewingTalkBtn: common.viewingTalkBtn,
		pageTo: common.pageTo,
		motion: motion.motion,

		layerOpen: Bases.layerOpen,
		layerClose: Bases.layerClose,
		layerConfirm: Bases.layerConfirm,
		pages: Bases.pages,

		submitForm: contact.submitForm,
		vrContactConfirm: contact.vrContactConfirm,
		checkedEmpty: contact.checkedEmpty,
		resetForm: contact.resetForm,

		strengthToggle: aboutEvent.strengthToggle,

		vrLayerOpen: vr.vrLayerOpen,
		vrLayerClose: vr.vrLayerClose,
		menuToggleHandler: vr.menuToggleHandler,

		aboutClientSlider: slider.aboutClientSlider,
	};
})($);


// decoRotate(){
// 	const deco = $('.deco4');
// 	const bodyHeight = $('body').height() - $(window).height();
// 	let deg = - $(window).scrollTop() * (360 / bodyHeight);
// 	deco.css({
// 		'transform': 'rotate('+deg+'deg)',
// 	});
// },


// import { SelectCustom } from './select';
// selectEvent: function() {
// 	// select
// 	const selectBox = $('select.js-select');
// 	let SelectList;
// 	selectBox.each(function() {
// 		SelectList = new SelectCustom($(this));
// 	});
// },