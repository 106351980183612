import $ from 'jquery';
import { gsap } from 'gsap';
import { SplitText } from "gsap/SplitText";
import { Bases } from './bases';

gsap.registerPlugin(SplitText);

const aboutEvent = {
	strengthActive: true,

	motion(){
		$('.about__strength').each(function(){
			const top = $(this).offset().top;
			const scrollTop = $(window).scrollTop();
			const windowHeight = $(window).height();
			if (scrollTop > top - (windowHeight - (windowHeight * 0.5))) {
				if( aboutEvent.strengthActive ){
					$('.about__strength .maintenance').addClass('is-active').removeClass('is-hidden');
					aboutEvent.strengthActive = false;
				}
			}
		});
	},

	strengthToggle(element){
		const el = $(element).parent();
		if( el.hasClass('is-active')){
			el.removeClass('is-active').addClass('is-hidden');
		}else{
			el.addClass('is-active').removeClass('is-hidden');
			if( window.innerWidth <= Bases.breakPointMobile ){
				let top = el.offset().top - $('header').outerHeight() + 2;
				$('html').animate({ scrollTop: top }, 300);
			}
		}
	},

	clientRandom(){
		const item = $('.about__clients .item');
		let len = window.innerWidth > Bases.breakPointMobile ? item.length : item.length / 2;
		let array = [];
		for(let i = 1; i<= len; i++){array.push(i);}
		array.sort(() => Math.random() - 0.5);
		item.each(function(idx){
			const num = array[idx];
			$(this).css('transition-delay', num * 0.04 +'s');
		});
	},

	textSliceCustom(title, gap = 0.03){
		const $title = $(title);
		$title.each(function(){
			const title = $(this).text();
			$(this).text('');
			const textArray = title.split('');
			textArray.forEach((el, idx)=>{
				let number = idx * gap;
				const elSpan = `<span>${el}<span class="line" style="transition-delay:${number.toFixed(2)}s"></span></span>`;
				$(this).append(elSpan);
			})
		});
	},

	init(){
		if($('.about__intro').length > 0) {
			aboutIntro();
			aboutEvent.motion();
			$(window).on('scroll', function () {
				aboutEvent.motion();
			});
		}
		if($('.about__clients').length > 0) {
			aboutEvent.clientRandom();
		}
		if($('.about__strength').length > 0) {
			aboutEvent.textSliceCustom('.about__strength .point', 0.03);
		}
	}
}

const aboutIntro = () => {
	let tl = gsap.timeline(),
		mainSplitText,
		chars,
		subText,
		subChars;
	mainSplitText = new SplitText('.about__intro .main', { type: 'chars' });
	chars = mainSplitText.chars;
	subText = new SplitText('.about__intro .sub',  {type: 'lines'});
	subChars = subText.lines;
	let textTop = 100;
	if( window.innerWidth <= 768 ) textTop = 50;
	// if( window.innerWidth > 768 ){
	//
	// }else{
	// 	mainSplitText = new SplitText('.about__intro .main div', { type: 'chars' });
	// 	chars = mainSplitText.chars;
	// 	subText = new SplitText('.about__intro .sub div',  {type: 'chars'});
	// 	subChars = subText.chars;
	// }
	const info = $('.about__info');
	const intro = $('.about__intro');
	info.removeClass('hidden');
	intro.removeClass('hidden');

	// gsap.set("#quote", { perspective: 400 });
	tl.from(chars, {
		duration: 0.4,
		opacity: 0,
		// scale: 0,
		y: textTop,
		// x: 400,
		// rotationX: 60,
		// transformOrigin: "0% 50% -50",
		// ease: "back",
		stagger: 0.02
	})
	.from(subChars, {
		duration: 0.2,
		opacity: 0,
		// scale: 0,
		y: 50,
		// rotationX: 45,
		// transformOrigin: "0% 50% -50",
		// ease: "back",
		// stagger: 0.01
	}, '-=0.3')
	gsap.from(info.find('.images'), {
		duration: 0.5,
		opacity: 0
	})
	gsap.from(info.find('.desc > *'), {
		duration: 0.5,
		opacity: 0,
		x: 100,
		stagger: 0.1,
	});

}

export { aboutEvent }